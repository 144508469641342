<template>
  <b-row>
    <b-col md="2" sm="4" class="my-1">
      <b-form-group class="mb-0">
        <label class="d-inline-block text-sm-left mr-50"></label>
        <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
      </b-form-group>
    </b-col>
    <b-col md="6" sm="8" class="my-1">

    </b-col>
    <b-col md="4" class="my-1">
      <b-form-group label="" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput"
        class="mb-0">
        <b-input-group size="sm">
          <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
        </b-input-group>
      </b-form-group>
    </b-col>


    <b-col cols="12">
      <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="items" :fields="fields"
        :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :filter="filter"
        :filter-included-fields="filterOn" @filtered="onFiltered">
        <template #cell(wbs)="data">
          <b-row>
            <b-col md="5">
              <small class="text-primary">Nama SKPD</small>
              <h6 class="text-primary"><strong>{{ data.item.kode_dinas }} {{ data.item.nama_dinas }}</strong></h6>
              <small class="text-primary">Nama Sub Kegiatan</small>
              <h6 class="text-primary"><small><strong>{{ data.item.kode_sub_kegiatan }} {{ data.item.nama_sub_kegiatan
              }}</strong></small></h6>

              <h6> <small class="text-primary">Jenis Kegiatan {{ data.item.jenis_kegiatan }} </small><br> <small
                  class="text-primary">Lokasi Kegiatan {{ data.item.nama_kecamatan }} </small> </h6>



            </b-col>
            <b-col md="4" v-if="data.item.st_realisasi != '1' && data.item.nilai_kontrak == null">
              <b-alert variant="warning" show>
                <div class="alert-body">
                  <span><strong>Belum Kontrak</strong> </span>
                </div>
              </b-alert>
            </b-col>
            <b-col md="4" v-if="data.item.nilai_kontrak >= '1' && data.item.nominal_realisasi == null">
              <b-alert variant="info" show>
                <div class="alert-body">
                  <span><strong>Sudah Kontrak dan Belum ada Serapan Anggaran </strong> </span>
                </div>
              </b-alert>
            </b-col>

            <b-col md="4" v-if="data.item.st_realisasi == '1' && data.item.nilai_kontrak >= 1">
              <h6><small>Rekanan Pelaksan Kontrak </small><br>
                <small><strong>{{ data.item.nama_rekanan }}</strong></small>
              </h6>
              <h6><small>No / Tanggal Kontrak</small><br>
                <small><strong>{{ data.item.no_kontrak }} / {{ data.item.tgl_kontrak }}</strong></small>
              </h6>
              <h6><small>Mulai Kontrak</small><br>
                <small><strong>{{ data.item.tgl_mulai_kontrak }} - {{ data.item.tgl_selesai_kontrak }}</strong></small>
              </h6>
            </b-col>
            <b-col md=" 3">
              <h5 class="text-primary"><small>Pagu Anggaran</small><br>
                <strong><sup>Rp. </sup>{{ Rp(data.item.anggaran) }}</strong>
              </h5>
              <h5 class="text-primary"><small>Nilai Kontrak </small><br>
                <strong><sup>Rp. </sup>{{ Rp(data.item.nilai_kontrak) }}</strong>
              </h5>
              <h5 class="text-primary"><small>Realisasi Kontrak </small><br>
                <strong><sup>Rp. </sup>{{ Rp(data.item.nominal_realisasi) }}</strong>
              </h5>
            </b-col>
          </b-row>
        </template>
      </b-table>
    </b-col>

    <b-col cols="12">
      <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" size="sm"
        class="my-0" />
    </b-col>
  </b-row>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton, BAlert
} from 'bootstrap-vue'

export default {
  components: {
    BTable, BAlert,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
  },
  props: {
    items: {}
  },
  data() {
    return {
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [{
        key: 'wbs',
        label: '',
        thStyle: {
          display: 'none'
        }
      }],

    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({
          text: f.label,
          value: f.key
        }))
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  methods: {
    Rp(value) {
      let val = (value / 1).toFixed(0).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: index`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
<style>
.btn-block {
  display: block;
  width: 100%;
}
</style>